import useSWR from 'swr'

import { api } from 'utils/axios'
import { useCompanyStore } from 'store/company.store'
import type { IBaseReport, IBaseReportRow } from 'types/report.type'
import type { IBaseReportRowData, IBaseReportRowSection } from 'types/report.type'
import { formatDate } from 'utils/dates'

export const useReportAccounts = () => {
  const companyId = useCompanyStore((state) => state.id)
  const key = companyId ? ['report/accounts', companyId] : null

  const { data, isLoading } = useSWR<IBaseReport[]>(key, () => fetch(companyId))

  return { accounts: transform(data) || [], isLoading }
}

const fetch = (companyId: number | null) => {
  if (!companyId) return Promise.resolve([])
  const from = '2000-01-01'
  const to = formatDate(new Date(), 'YYYY-MM-DD')
  const params = { companyId, from, to }
  return api.get('/accounting/report/accounts', { params }).then((res) => res.data)
}

export interface IReportAccount {
  key: string
  id?: string
  type: string
  label: string
  rows?: IReportAccount[]
  reportType: string
}

//TODO: make transform functions shared  so we can use in other places

export const transform = (data: IBaseReport[] | undefined) => {
  if (!data) return []
  return data
    .map((item, _) => {
      const type = item.meta.name.toLowerCase()
      return item.report.map((row) => transforRow(row, type)).filter((r) => !!r) || []
    })
    .flat()
    .filter((r) => !!r)
}

export const transforRow = (data: IBaseReportRow, reportType: string) => {
  const { id, type } = data

  if (type === 'Data') {
    const key = data.key + '_' + reportType
    const item = data as IBaseReportRowData
    const label = item.name
    return { key, id, type, label, reportType }
  } else {
    const key = data.key + '_' + reportType
    const item = data as IBaseReportRowSection
    const label = item.name || item.summary?.name || ''
    const rows: IReportAccount[] = item.rows?.map((row) => transforRow(row, reportType)).filter((r) => !!r)

    if (rows && item.summary) {
      rows.push({
        key: item.key + '_sum_' + reportType,
        type: 'Summary',
        label: item.summary.name,
        reportType: reportType,
      })
    }

    return { key, id, type, label, rows, reportType }
  }
}

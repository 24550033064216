import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Flex, Result } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

import { FullLoader } from 'components/FullLoader'

import DataTable from './components/DataTable'
import ChartRenderer from './components/ChartRenderer'

import { SelectChartType } from './components/SelectChartType'
import { SelectPeriod } from './components/SelectPeriod'
import { SelectAccountingMethod } from './components/SelectAccountingMethod'
import { SelectAccounts } from './components/SelectAccounts'
import { SelectDataSource } from './components/SelectDataSource'
import { useCompany } from 'hooks/useCompany'
import { useAccouningReport } from 'hooks/useAccouningReport'
import { AccountingMethodType, ChartType, DataSourceType, PeriodType } from 'types/chart.types'
import { IBaseReport, IReportParams } from 'types/report.type'
import { ConnectionType } from 'types/company.types'
import { transform } from 'hooks/useReportAccounts'

export type AccountFormattedData = {
  [key: string]: string | number
  name: string
}

const DEFAULT_SHOPIFY_ACCOUNT = '0-0_gross'
const DEFAULT_QUICKBOOKS_ACCOUNT = '0-0_profitandloss'

export function ChartsTab() {
  const params = useParams()
  const companyId = Number(params?.id)

  const { company } = useCompany()
  const navigate = useNavigate()

  const [chartType, setChartType] = useState<ChartType>('line')
  const [period, setPeriod] = useState<PeriodType>('Month')
  const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs().startOf('month').add(-3, 'months'))
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs().startOf('month').add(-1, 'months'))
  const [method, setMethod] = useState<AccountingMethodType>('Accrual')
  const [accounts, setAccounts] = useState<string[]>([DEFAULT_QUICKBOOKS_ACCOUNT])
  const [source, setSource] = useState<DataSourceType[]>(['QB'])

  const filterParams: IReportParams = {
    type: 'accounts',
    companyId,
    from: formatFromDate(fromDate, period),
    to: formatToDate(toDate, period),
    method: method,
    summarize_column_by: period,
  }

  // qb accounts
  const { data: quickBooksAccountsData, isLoading: quickBooksAccountsLoading } = useAccouningReport<IBaseReport[]>(
    { ...filterParams, source: 'QB' },
    undefined,
    true,
  )

  // sp accounts
  const { data: shopifyAccountsData, isLoading: shopifyAccountsLoading } = useAccouningReport<IBaseReport[]>(
    { ...filterParams, source: 'SP' },
    undefined,
    true,
  )

  const accountsData = {
    QB: source.includes('QB') ? quickBooksAccountsData || [] : [],
    SP: source.includes('SP') ? shopifyAccountsData || [] : [],
  }

  const accountsLoading = quickBooksAccountsLoading || shopifyAccountsLoading

  // qb data
  const { data: quickBooksChartData, isLoading: quickBookChartDataLoading } = useAccouningReport<IBaseReport[]>(
    {
      ...filterParams,
      source: 'QB',
      type: 'charts',
    },
    undefined,
    true,
  )

  // sp data
  const { data: shopifyChartData, isLoading: shopifyChartDataLoading } = useAccouningReport<IBaseReport[]>(
    { ...filterParams, source: 'SP', type: 'charts' },
    '/commercial/report/shopify',
    true,
  )

  const chartData = [
    ...(source.includes('QB') ? quickBooksChartData || [] : []),
    ...(source.includes('SP') ? shopifyChartData || [] : []),
  ]

  const chartDataLoading = quickBookChartDataLoading || shopifyChartDataLoading

  const onPeriodChange = (newValue: PeriodType) => {
    setPeriod(newValue)

    const fromDateValuesByPeriod: Record<'Month' | 'Year' | 'Quarter', Dayjs> = {
      Month: dayjs(toDate).subtract(3, 'month'),
      Quarter: dayjs(toDate).subtract(1, 'quarter'),
      Year: dayjs(toDate).subtract(1, 'year'),
    }

    const newFromDate = newValue && fromDateValuesByPeriod[newValue]
    setFromDate(newFromDate || dayjs())
  }

  const onAccountMethodChange = (e: AccountingMethodType) => {
    setMethod(e)
  }

  const onSourceChange = (source: DataSourceType[]) => {
    setSource(source)

    if (source.includes('SP')) {
      setMethod('Accrual')
    }

    if (source.length === 2) {
      return
    }

    const shopifyAccountKeys = transform(shopifyAccountsData).map((i) => i.key)

    let filteredAccounts = source.includes('QB')
      ? accounts.filter((acc) => !shopifyAccountKeys.includes(acc))
      : accounts.filter((acc) => shopifyAccountKeys.includes(acc))

    filteredAccounts = filteredAccounts.length
      ? filteredAccounts
      : [source.includes('QB') ? DEFAULT_QUICKBOOKS_ACCOUNT : DEFAULT_SHOPIFY_ACCOUNT]

    setAccounts(filteredAccounts)
  }

  const accountsFormattedData = useMemo(() => getAccountsData(chartData, accounts), [chartData, accounts])

  const chartFormattedData = useMemo(() => getAccountsChartData(accountsFormattedData), [accountsFormattedData])

  const csvName = `${period}ly_report_${dayjs(fromDate).format('DD-MM-YYYY')}_${dayjs(toDate).format('DD-MM-YYYY')}.csv`

  const qbo = (company?.connections || []).find((i) => i.type.id === ConnectionType.QBO)
  const isConnected = qbo?.status === 1

  const onReconnect = () => navigate('./settings/accounting', { relative: 'path' })

  if (!company?.id) return <FullLoader />
  if (!isConnected)
    return (
      <Result
        status="warning"
        title="QuickBooks Online is not connected"
        subTitle="Please connect to QuickBooks Online to view financial reports."
        extra={
          <Button type="primary" onClick={onReconnect}>
            Connect to QuickBooks Online
          </Button>
        }
      />
    )

  return (
    <Flex gap={20} vertical>
      <Flex style={{ width: '100%' }} justify="space-between" align="center">
        <SelectAccountingMethod value={method} onChange={onAccountMethodChange} disabled={source.includes('SP')} />
        <SelectPeriod
          periodValue={period}
          onPeriodChange={onPeriodChange}
          fromDate={fromDate}
          onFromDateChange={setFromDate}
          toDate={toDate}
          onToDateChange={setToDate}
        />
      </Flex>
      <Flex gap={10} justify="space-between">
        <SelectDataSource value={source} onChange={onSourceChange} />
        <SelectAccounts options={accountsData} value={accounts} onChange={setAccounts} loading={accountsLoading} />
        <SelectChartType value={chartType} onChange={setChartType} />
      </Flex>

      <ChartRenderer
        type={chartType}
        accountsData={accountsFormattedData}
        accountsChartData={chartFormattedData}
        chartDataLoading={chartDataLoading}
      />

      <DataTable data={chartFormattedData} csvName={csvName} />
    </Flex>
  )
}

const formatToDate = (toDate: Dayjs | null, period: PeriodType) => {
  let formattedDate = dayjs(toDate)

  const formatedDateByPeriod: Record<'Month' | 'Year' | 'Quarter', Dayjs> = {
    Month: formattedDate.endOf('month'),
    Quarter: formattedDate.endOf('quarter'),
    Year: formattedDate.endOf('year'),
  }

  let date = period ? formatedDateByPeriod[period] : formattedDate

  return date.format('YYYY-MM-DD')
}

const formatFromDate = (fromDate: Dayjs | null, period: PeriodType) => {
  let formattedDate = dayjs(fromDate)

  const formatedDateByPeriod: Record<'Month' | 'Year' | 'Quarter', Dayjs> = {
    Month: formattedDate.startOf('month'),
    Quarter: formattedDate.startOf('quarter'),
    Year: formattedDate.startOf('year'),
  }

  let date = period ? formatedDateByPeriod[period] : formattedDate

  return date.format('YYYY-MM-DD')
}

const getSingleAccountData = (
  chartData: IBaseReport[],
  parentName: string,
  selectedKey: string,
  isSummary = false,
): AccountFormattedData => {
  const keysArray = selectedKey.split('-').slice(1)
  const parentType = chartData.find((el: any) => el.meta.name?.toLowerCase() === parentName)
  const parentTypeMeta = parentType?.meta
  const parentTypeData = parentType?.report

  const isQB =
    parentTypeMeta?.name === 'ProfitAndLoss' ||
    parentTypeMeta?.name === 'CashFlow' ||
    parentTypeMeta?.name === 'BalanceSheet'

  let accountData
  for (const key of keysArray) {
    if (accountData && selectedKey === accountData.key) {
      continue
    }
    if (accountData) {
      accountData = accountData.rows[key]
    } else {
      // @ts-ignore fix key type
      accountData = parentTypeData?.[key]
    }
  }
  accountData = isSummary ? accountData?.summary : accountData?.summary || accountData

  const { _key, _id, _data, _type, _total, _value, account, name, ...chartValues } = accountData || {}

  // console.info('getSingleAccountData', chartValues, name, account, isQB)
  return { ...chartValues, name: name || account, isQB }
}

const getAccountsData = (chartData: IBaseReport[] | undefined, accounts: string[]) => {
  if (!chartData || !accounts) {
    return []
  }

  return accounts.map((accountName) => {
    const _acc = accountName.split('_')
    const selectedKeys = _acc[0]
    const parentKey = _acc.length === 2 ? _acc[1] : _acc[2]
    const isSummary = _acc.length === 3

    const accountChartData = getSingleAccountData(chartData, parentKey, selectedKeys, isSummary)
    return accountChartData
  })
}

const getAccountsChartData = (accountsData: AccountFormattedData[] | never[]) => {
  if (!accountsData?.length) return []

  const prototype = accountsData.find((acc) => Object.values(acc).some((i) => !!i))
  if (!prototype) return []

  const periods = Object.keys(prototype)
    .filter((k) => k !== 'name')
    .filter((k) => k !== 'isQB')

  const data: any = periods.map((account: string) => {
    let periodData: { [key: string]: number | string } = {}
    let meta: any = {}

    accountsData?.forEach((item: any) => {
      const type = item.isQB ? 'QuickBooks Online' : 'Shopify'

      if (item.name !== undefined) {
        periodData[item.name] = Number(item[account])
        meta[item.name] = type
      }
    })

    return { name: account, ...periodData, meta }
  })

  return data
}

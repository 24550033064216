import { CSVBoxButton } from '@csvbox/react'
import { Button, Flex, Select, Typography, Tooltip, notification } from 'antd'
import { useUser } from 'hooks/useUser'
import { useEffect, useState } from 'react'

const { Text } = Typography

interface Props {
  isLiability?: boolean;
  isDateNotSelected?: boolean;
  onChange: (data: any[]) => void;
}

const DATE_SELECTION_MESSAGE = 'Please select the date range, GL account, and corresponding balances before proceeding.'

export function CsvUpload(props: Props) {
  const { isLiability, onChange, isDateNotSelected } = props
  const { user } = useUser()

  const [selected, setSelected] = useState<string>(keys[0])

  useEffect(() => {
    setSelected(isLiability ? keys[2] : keys[0])
  }, [isLiability])

  const onImport = (_: boolean, metadata?: any) => {
    if (metadata.import_status !== 'success') {
      return notification.error({
        message: 'Error uploading file',
        description: 'Please try again',
      })
    }
    onChange(metadata.rows)
  }

  const selectedOption = options.find((o) => o.value === selected)

  const uploadButton = (launch: () => void, isLoading: boolean) => (
    <Tooltip title={isDateNotSelected ? DATE_SELECTION_MESSAGE : ''}>
      <Button
        type="primary"
        loading={isLoading}
        onClick={launch}
        style={{ width: 120 }}
        disabled={isDateNotSelected}
      >
        Upload
      </Button>
    </Tooltip>
  )

  return (
    <Flex vertical gap={10}>
      <Flex gap={10}>
        <Select
          value={selected}
          style={inputStyle}
          options={options}
          onChange={setSelected}
          disabled={isDateNotSelected}
        />
        <CSVBoxButton
          key={selected}
          user={{ user_id: user?.id }}
          licenseKey={selected}
          onImport={onImport}
          render={uploadButton}
        />
      </Flex>
      <Text type="secondary">
        <b>Please Upload Your Bank Statement In CSV Format. Please Note:</b>
        <ul>
          <li>Checking Accounts = Assets</li>
          <li>Credit Cards = Liabilities</li>
        </ul>
      </Text>
      <Text type="secondary">{selectedOption?.desc}</Text>
    </Flex>
  )
}

const inputStyle: React.CSSProperties = {
  width: '100%',
}

const options = [
  {
    value: 'hEKT8TOuof8YtPhzNVFsrgqUnZhN38',
    label: 'Asset account - Amounts in one column',
    desc: (
      <>
        <b>Asset Account - Amounts in one column</b>
        <ul>
          <li>Money Spent - These amounts need to be negative</li>
          <li>Money Received - These amounts need to be positive</li>
        </ul>
      </>
    ),
  },
  {
    value: 'LHtnMpReSYicllPWJ5KcVU9nOqOccV',
    label: 'Asset account - Amounts in two columns',
    desc: (
      <>
        <b>Asset Account - Amounts in two columns</b>
        <ul>
          <li>Money Spent & Received - Both amounts need to be positive</li>
        </ul>
      </>
    ),
  },
  {
    value: 'zlN9QCA2vqce8gFPayWaHiJWPfGaCT',
    label: 'Liability account - Amounts in one column',
    desc: (
      <>
        <b>Liability account - Amounts in one column</b>
        <ul>
          <li>Money Spent - These amounts need to be positive</li>
          <li>Money Received - These amounts need to be negative</li>
        </ul>
      </>
    ),
  },
  {
    value: 'v8JR5rcYKkRk0SgLY7Z1EuQJGsDhOL',
    label: 'Liability account - Amounts in two column',
    desc: (
      <>
        <b>Liability account - Amounts in two columns</b>
        <ul>
          <li>Money Spent & Received - Both amounts need to be positive</li>
        </ul>
      </>
    ),
  },
]

const keys = [
  'hEKT8TOuof8YtPhzNVFsrgqUnZhN38',
  'LHtnMpReSYicllPWJ5KcVU9nOqOccV',
  'zlN9QCA2vqce8gFPayWaHiJWPfGaCT',
  'v8JR5rcYKkRk0SgLY7Z1EuQJGsDhOL',
]

export default CsvUpload
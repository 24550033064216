import {DittofeedSdk, IdentifyData, ScreenData, TrackData} from '@dittofeed/sdk-web';
import { config } from "./config";

// Create a singleton instance
type DittofeedInstance = Awaited<ReturnType<typeof DittofeedSdk.init>>;
let dittoedInstance: DittofeedInstance | null = null;
export const initializeDittofeed = async () => {
    if (process.env.REACT_APP_ENV === 'production') {
        console.log('Initializing Dittofeed', config.dittofeed);
        dittoedInstance = await DittofeedSdk.init({
            writeKey: config.dittofeed.writeKey || '',
            host: config.dittofeed.host || 'https://dittofeed.equilityhq.com',
        });
        return dittoedInstance;
    }
    return null;
};

// Export methods that use the initialized instance
export const Dittofeed = {
    screen: (params:ScreenData) => {
        if (dittoedInstance) {
            DittofeedSdk.screen(params);
        }
    },
    track: (params:TrackData) => {
        if (dittoedInstance) {
            DittofeedSdk.track(params);
        }
    },
    identify: (params:IdentifyData) => {
        if (dittoedInstance) {
            DittofeedSdk.identify(params);
        }
    },
    flush: async () => {
        if (dittoedInstance) {
            await DittofeedSdk.flush();
        }
    }
};
import useSWR from 'swr'
import { IDefaultRule } from '../types/default-rules.types'

export function useDefaultRules(entity?: number | null) {
  const url = entity ? `default-rules?entity=${entity}` : 'default-rules'
  const { data, error, isLoading, isValidating, mutate } = useSWR<IDefaultRule[]>(url)

  return {
    data: data?.sort((a, b) => a.position - b.position),
    error,
    isLoading: isLoading || isValidating,
    revalidate: () => mutate(data)
  }
}
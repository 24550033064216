import { api } from './axios'

export function linkQBO(url: string) {
  const popup = window.open(url, 'QuickBooks', 'width=760,height=640,opener')

  let i = 0
  localStorage.setItem('eq_message', '')

  return new Promise((resolve, reject) => {
    window.addEventListener(
      'message',
      (msg) => {
        if (msg?.data?.type === 'COMPANY_ADDED') resolve(msg.data)
      },
      false,
    )

    const interval = setInterval(function () {
      i++
      const message = localStorage.getItem('eq_message')

      if (message) {
        const data = JSON.parse(message || '{}')
        if (data?.type === 'COMPANY_ADDED') resolve(data)
        if (data?.type === 'COMPANY_ADD_DENIED') reject(`Error: ${data?.msg || 'Access denied.'}`)
      }

      if (popup?.closed || i > 300) {
        clearInterval(interval)
        reject('Error: Access denied.')
      }
    }, 500)
  })
}

export async function connectQBO(companyId: number): Promise<{ message: string }> {
  try {
    const params = { provider: 'QBO' };
    const { data } = await api.post(`companies/${companyId}/connection/link`, { provider: 'QBO' });

    localStorage.setItem('eq_message', '');
    const { type, search }: any = await linkQBO(data.url);
    if (type === 'COMPANY_ADD_DENIED') {
      throw new Error('Access denied by QuickBooks');
    }

    const postData = { ...params, ...search, token: search.code };
    await api.post(`companies/${companyId}/connection`, postData);
    return { message: 'Connected to QuickBooks' };
  } catch (error) {
    return Promise.reject(error);
  }
}
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Flex, Result, Spin, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

const opener = window.opener

export function QboConnectionPage() {
  const [ready, setReady] = useState(false)
  const [fail, setFail] = useState(false)

  const [searchParams] = useSearchParams()
  const search = Object.fromEntries(searchParams) as any

  useEffect(() => {
    if (search.error && search.error === 'access_denied') {
      const t = { type: 'COMPANY_ADD_DENIED', msg: 'Access denied by QuickBooks' }
      localStorage.setItem('eq_message', JSON.stringify(t))
      opener?.postMessage(t, '*')
      setFail(true)
    } else if (search.code && search.realmId) {
      const t = { type: 'COMPANY_ADDED', search }
      localStorage.setItem('eq_message', JSON.stringify(t))
      opener?.postMessage(t, '*')
      setReady(true)
    }
  }, [search])

  const onClose = () => {
    window.close()
  }

  if (fail)
    return (
      <Flex style={style} align="center" justify="center">
        <Result
          status="error"
          title="Access denied by QuickBooks"
          subTitle="Please try again."
          extra={[
            <Button onClick={onClose} danger key="error-key-qbo">
              Close
            </Button>,
          ]}
        />
      </Flex>
    )

  if (ready)
    return (
      <Flex style={style} align="center" justify="center">
        <Result
          status="success"
          title="Successfully connected to QuickBooks Online"
          subTitle="You can now close this window and go back to the app."
          extra={[
            <Button type="primary" onClick={onClose} key="success-key-qbo">
              Close
            </Button>,
          ]}
        />
      </Flex>
    )

  return (
    <Flex style={style} align="center" justify="center">
      <Flex vertical align="center">
        <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />
        <Title level={3}>Connecting to QuickBooks Online</Title>
        <Text type="secondary">Please wait</Text>
      </Flex>
    </Flex>
  )
}

const style: React.CSSProperties = {
  width: '100%',
  height: '100%',
}

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { Alert, Button, Card, Col, DatePicker, Divider, Flex, Result, Spin, notification } from 'antd'
import { Form, Typography, Row, Slider } from 'antd'
import { SettingOutlined } from '@ant-design/icons'

import { SelectAccount } from 'components/SelectAccount'
import { HelpTooltip } from 'components/HelpTooltip'
import { InputCurrency } from 'components/InputCurrency'
import { AlertPlugin } from 'components/AlertPlugin'
import { CsvUpload } from './components/CsvUpload'
import { PlaidList } from './components/PlaidList'
import VideoTooltip from 'components/VideoTooltip'

import { useRole } from 'hooks/useRole'
import { useAccounts } from 'hooks/useAccounts'
import { useCompany } from 'hooks/useCompany'
import { useReconcile } from 'hooks/useReconcile'
import { useBankTransactions } from 'hooks/useBankTransactions'
import { useTransactions } from 'hooks/useTransactions'
import { formatDate, parseDate, prevMonthStart } from 'utils/dates'
import { toCurrency } from 'utils/numbers'
import { getErrorMsg } from 'utils/geterror'
import { isPluginInstalled } from 'utils/plugin'
import { csvBoxToTxs, pladToTxs, reportToTsx } from 'utils/tx'
import { ConnectionType } from 'types/company.types'
import { RoleType } from 'types/user.types'
import type { CsvBoxTx, ITx } from 'types/tx.types'

import { useFlags } from 'flagsmith/react'
import dayjs from 'dayjs'

const { Text } = Typography
const { RangePicker } = DatePicker

const SourceDataHeader = () => (
  <Flex align="center" gap={5}>
    <Text>Source Data Import</Text>
    <HelpTooltip
      text="Choose how to import your source data transactions below by linking an account or uploading a CSV document" />
  </Flex>
)

export function RecRun() {
  const navigate = useNavigate()

  const flags = useFlags(['disable_chrome_plugin'])

  const [searchParams, setSearchParams] = useSearchParams()
  const search = Object.fromEntries(searchParams) as any

  const [form] = Form.useForm<IFormData>()
  const [bankTxs, setBankTsx] = useState<ITx[] | null>(null)
  const [plaidAccount, setPlaidAccount] = useState<string>()

  const { accounts } = useAccounts()
  const { company, isLoading } = useCompany()
  const { isAllowed } = useRole(RoleType.Member)

  const accountId = Form.useWatch<string>('accountId', form)
  const daterange = Form.useWatch('daterange', form)

  const companyId = company?.id || 0
  const to = daterange ? formatDate(daterange[1], 'YYYY-MM-DD') : '2000-01-01'
  const from = daterange ? formatDate(daterange[0], 'YYYY-MM-DD') : '2000-01-02'
  const before = prevMonthStart(from) // to get the previous month transactions

  const txs = useTransactions({ accountId, companyId, from: before, to })

  const reconcile = useReconcile({ id: null })
  const plaidData = useBankTransactions({ id: plaidAccount || null, from, to })

  const qbo = (company?.connections || []).find((i) => i.type.id === ConnectionType.QBO)
  const isConnected = qbo?.status === 1

  const isPlugin = isPluginInstalled()

  const isLiability = accounts?.find((a) => a.platformId === accountId)?.isLiability

  useEffect(() => {
    if (plaidData.data && !bankTxs) setBankTsx(pladToTxs(plaidData.data, !isLiability))
    if (plaidData.error) {
      notification.error(getErrorMsg(plaidData.error.response.data))
    }
  }, [plaidData])

  useEffect(() => {
    if (!search.sync) return
    if (search.accountId) form.setFieldsValue({ accountId: search.accountId } as any)
    setSearchParams({})
    localStorage.removeItem('qbosync')
    notification.success({ message: 'Success', description: 'QuickBooks Online data has been updated' })
  }, [search])

  useEffect(() => {
    if (accountId && !form.isFieldTouched('daterange')) {
      const account = accounts.find((a) => a.platformId === accountId)

      if (account) {
        const reconciledThrough = account?.reconciledThrough?.split('T')[0]

        const from = reconciledThrough ? parseDate(reconciledThrough)?.add(1, 'day') : null
        const to = from?.endOf('month')

        if (from && to && !isNaN(from.unix()) && !isNaN(to.unix())) {
          form.setFieldsValue({ daterange: [from, to] as any })
        } else {
          const today = dayjs() // Get today's date
          const startOfToday = today.startOf('day') // Start of today
          const endOfToday = today.endOf('day') // End of today
          form.setFieldsValue({ daterange: [startOfToday, endOfToday] as any })
        }
      }
    }
  }, [accountId])

  useEffect(() => {
    if (search.sync) return
    if (!search.accountId) return
    if (!accounts?.length || !company) return
    if (form.isFieldsTouched()) return

    form.setFieldsValue({
      accountId: search.accountId,
      account: search.accountId,
      beginBalance: parseFloat(search.startingBalance || '0'),
      endBalance: parseFloat(search.endingBalance || '0'),
      daterange: [undefined, parseDate(search.to)],
    } as any)
  }, [search, accounts, company, daterange])

  useEffect(() => {
    if (search.accountId) return

    const account = accounts.find((a) => a.platformId === accountId)
    if (!account) return

    if (!form.isFieldTouched('beginBalance')) {
      form.setFieldsValue({ beginBalance: account.endingBalance })
    }

    if (!form.isFieldTouched('daterange')) {
      const reconciledThrough = account.reconciledThrough?.split('T')[0]
      const from = parseDate(reconciledThrough || '')?.add(1, 'day')
      const to = from?.endOf('month')
      form.setFieldsValue({ daterange: [from, to] } as any)
    }
  }, [accountId, search])

  const onCSVChange = (data: CsvBoxTx[]) => {
    const txs = csvBoxToTxs(data)
    setBankTsx(txs)
  }

  const onSubmit = (values: IFormData) => {
    if (txs.error) return notification.error(getErrorMsg(txs.error))
    if (!bankTxs) return notification.warning({ message: 'Warning', description: 'No bank transactions' })
    if (!txs.data) return notification.warning({ message: 'Warning', description: 'No account transactions' })
    if (!isConnected) return notification.error({ message: 'Error', description: 'QuickBooks Online is not connected' })

    const balanceDiff = (values.endBalance || 0) - (values.beginBalance || 0)
    const bankBalance = bankTxs.reduce((a, b) => a + b.amount, 0)

    if (toCurrency(balanceDiff) !== toCurrency(bankBalance)) {
      const message = plaidAccount ? bankTestMsg(bankBalance, balanceDiff) : cvsTestMsg(bankBalance, balanceDiff)
      return notification.warning({ message: 'Warning', description: message })
    }

    const qboTxs = reportToTsx(txs.data, accountId, true)

    const rec = {
      ...values,
      companyId: company?.id,
      from: formatDate(values.daterange[0], 'YYYY-MM-DD'),
      to: formatDate(values.daterange[1], 'YYYY-MM-DD'),
      temp_data: { bank: bankTxs || [], qbo: qboTxs || [] },
      starting_balance: values.beginBalance,
      ending_balance: values.endBalance,
      result: [],
    }

    reconcile
      .create(rec)
      .then((r) => navigate(`./result/${r.id}`, { state: r }))
      .catch((err) => notification.error(getErrorMsg(err)))
  }

  const onCancel = () => {
    setPlaidAccount(undefined)
    setBankTsx(null)
  }

  const onRefresh = () => {
    const connectionInfo = qbo?.connectionInfo || '{}'
    const c = { qbo_id: connectionInfo.realm_id, id: company?.id }
    const backurl = new URL(window.location.href.split('?')[0])
    if (accountId) backurl.searchParams.set('accountId', accountId)
    const data = { type: 'FROM_PAGE_TASK', company: c, backurl: backurl.toString() }
    localStorage.setItem('qbosync', JSON.stringify({ date: Date.now(), url: backurl.toString() }))
    window.postMessage(data, '*')
  }

  if (!isAllowed) return null

  return (
    <Flex vertical>
      {!isConnected && !isLoading && !flags?.disable_chrome_plugin?.enabled && (
        <Alert
          message="Quickbooks Integration"
          description={
            <Text>
              <a href={`/company/${company?.id}/settings/accounting`}>Connect your accounting platform</a> to use the
              features
            </Text>
          }
          type="warning"
          showIcon
        />
      )}
      <AlertPlugin />
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={24}>
          <Col span={16}>
            <Divider orientation="left">Reconciliation Parameters</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label={<DaterangeLabel />} name="daterange" required rules={rules}>
                  <RangePicker style={inputStyle} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="GL Account" name="accountId" required rules={rules}>
                  <SelectAccount accounts={accounts} onChange={() => {
                  }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Beginning Balance" name="beginBalance" required rules={rules}>
                  <InputCurrency style={inputStyle} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Ending Balance" name="endBalance" required rules={rules}>
                  <InputCurrency style={inputStyle} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Divider orientation="left">Auto matching</Divider>
            <Form.Item label={<DayrangeLabel />} name="dayrange" initialValue={7}>
              <Slider min={1} max={10} />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left"><SourceDataHeader /></Divider>
        {!!bankTxs && (
          <Result
            icon={false}
            status="success"
            title="Bank Transactions"
            subTitle={bankTxs?.length + ' transactions retrieved from bank account'}
            extra={<Button onClick={onCancel}>Cancel</Button>}
          />
        )}
        {!bankTxs && (
          <Row gutter={24}>
            <Col span={11}>
              <Card size="small" title="Linked Accounts" extra={<SetupBank />}>
                <Spin spinning={plaidData.isLoading}>
                  <PlaidList onSelect={setPlaidAccount} isDateNotSelected={daterange == null} />
                </Spin>
              </Card>
            </Col>
            <Col span={2}>
              <Divider>or</Divider>
            </Col>
            <Col span={11}>
              <Card
                size="small"
                title="Upload CSV Document"
                extra={
                  <VideoTooltip
                    videoUrl="https://storage.googleapis.com/equility-website-assets/documentation-videos/CSV-Upload-Instructions.mp4"
                    buttonText="View CSV Upload Tutorial"
                  />
                }
              >
                <CsvUpload onChange={onCSVChange} isLiability={isLiability} isDateNotSelected={daterange == null} />
              </Card>
            </Col>
          </Row>
        )}
        <Divider />
        <Flex justify="space-between">
          {isPlugin && (
            <Button onClick={onRefresh} loading={reconcile.isLoading}>
              Update from QuickBooks
            </Button>
          )}
          <Button type="primary" style={runStyle} htmlType="submit" loading={reconcile.isLoading}>
            Run
          </Button>
        </Flex>
      </Form>
    </Flex>
  )
}

const rules = [
  { required: true },
]

const SetupBank = () => {
  const navigate = useNavigate()
  const onClick = () => navigate('../settings/banks', { relative: 'path' })
  return (
    <Button type="text" size="small" onClick={onClick}>
      <SettingOutlined /> Setup
    </Button>
  )
}

const DaterangeLabel = () => (
  <Flex align="center" gap={5}>
    <Text>Date Range</Text>
    <HelpTooltip
      text="All uncleared transactions that are within one month of the ‘Start Date’ will be imported into Equility. Please amend the start date, if you have uncleared transactions that are older than this." />
  </Flex>
)

const DayrangeLabel = () => (
  <Flex align="center" gap={5}>
    <Text>Specify number of days</Text>
    <HelpTooltip text="Number Of Days Before And After Transaction Date To Look For Potential Matches." />
  </Flex>
)

const bankTestMsg = (bankBalance: number, balanceDiff: number) =>
  `The transactions within bank data total ${toCurrency(bankBalance)}, ` +
  'which does not match the different between the ‘Beginning Balance’' +
  `and ‘Ending Balance’ of ${toCurrency(balanceDiff)}.`

const cvsTestMsg = (bankBalance: number, balanceDiff: number) =>
  `The summation of the transactions uploaded via CSV totals ${toCurrency(bankBalance)}, ` +
  `which does not match the difference between the ‘Beginning Balance’ and ‘Ending Balance’ of ${toCurrency(balanceDiff)} ` +
  `that was entered within the reconciliation parameters. Please upload a new CSV file with a summation that equals ${toCurrency(balanceDiff)} in order to continue.`

const runStyle: React.CSSProperties = {
  width: 200,
}

const inputStyle: React.CSSProperties = {
  width: '100%',
}

interface IFormData {
  account: string
  beginBalance: number
  endBalance: number
  dayrange: number
  daterange: [string, string]
}
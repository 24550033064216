import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Flex, Result, Spin, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { api } from 'utils/axios'

const { Title, Text } = Typography

const opener = window.opener

export function ShopifyConnectionPage() {
  const [ready, setReady] = useState(false)
  const [fail, setFail] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const callShopifyCallback = async () => {
      try {
        const response = await api.get('webhooks/shopify/callback', {
          params: {
            shop: searchParams.get('shop'),
            code: searchParams.get('code'),
            hmac: searchParams.get('hmac'),
            state: searchParams.get('state'),
            timestamp: searchParams.get('timestamp'),
            host: searchParams.get('host'),
          },
        })

        if (response.data.received && response.data.handled) {
          const t = {
            type: 'SHOPIFY_CONNECTED',
            data: {
              ...response.data,
              success: true // Explicitly set success flag
            }
          }
          localStorage.setItem('eq_message', JSON.stringify(t))
          opener?.postMessage(t, '*')

          if (response.data.exists) {
            navigate('/')
            return
          }

          setReady(true)
        } else {
          throw new Error('Connection validation incomplete')
        }
      } catch (error) {
        console.error('Shopify connection error:', error)
        const errorMessage = error instanceof Error ? error.message : 'Failed to connect to Shopify'
        const t = {
          type: 'SHOPIFY_CONNECTION_FAILED',
          msg: errorMessage
        }
        localStorage.setItem('eq_message', JSON.stringify(t))
        opener?.postMessage(t, '*')
        setFail(true)
      }
    }

    callShopifyCallback()
  }, [searchParams])

  const onClose = () => {
    window.close()
  }

  if (fail) {
    return (
      <Flex style={style} align="center" justify="center">
        <Result
          status="error"
          title="Failed to connect to Shopify"
          subTitle="Please try again."
          extra={[
            <Button onClick={onClose} danger key="close-error">
              Close
            </Button>,
          ]}
        />
      </Flex>
    )
  }

  if (ready) {
    return (
      <Flex style={style} align="center" justify="center">
        <Result
          status="success"
          title="Successfully connected to Shopify"
          subTitle="You can now close this window and go back to the app."
          extra={[
            <Button type="primary" onClick={onClose} key="close-success">
              Close
            </Button>,
          ]}
        />
      </Flex>
    )
  }

  // LYP-1200 - Loading is now a default state.
  return (
    <Flex style={style} align="center" justify="center">
      <Flex vertical align="center">
        <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />
        <Title level={3}>Connecting to Shopify</Title>
        <Text type="secondary">Please wait</Text>
      </Flex>
    </Flex>
  )
}

const style: React.CSSProperties = {
  width: '100%',
  height: '100%',
}

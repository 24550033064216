import useSWR, { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'
import { notification } from 'antd'
import type { AxiosError } from 'axios'

import { api } from 'utils/axios'
import { useCompanyStore } from 'store/company.store'
import { getErrorMsg } from 'utils/geterror'
import type { IRule } from 'types/company.types'

export function useRules() {
  const { mutate: revalidate } = useSWRConfig()

  const companyId = useCompanyStore((state) => state.id)
  const key = `companies/${companyId}/rules`

  const { data, isLoading, error } = useSWR<IRule[]>(key)
  const createMutation = useSWRMutation(key, createFetcher)
  const deleteMutation = useSWRMutation(key, deleteFetcher)
  const updateMutation = useSWRMutation(key, updateFetcher)

  const create = (data: Partial<IRule>) => {
    return createMutation.trigger(data, {
      // optimisticData: data,
      onSuccess: () => revalidate(`companies/${companyId}/rules`),
    })
  }

  const update = (data: Partial<IRule>) => {
    return updateMutation.trigger(data, {
      onSuccess: () => revalidate(`companies/${companyId}/rules`),
    })
  }

  const remove = (id: number) => {
    return deleteMutation.trigger(
      { id },
      {
        optimisticData: data?.filter((item) => item.id !== id),
        onSuccess: () => revalidate(`companies/${companyId}/rules`),
      },
    )
  }

  return {
    create,
    update,
    remove,
    rules: data,
    isLoading: isLoading || createMutation.isMutating || deleteMutation.isMutating,
    error: error || createMutation.error || deleteMutation.error,
  }
}

interface IUpdateData {
  arg: Partial<IRule>
}

const updateFetcher = (url: string, { arg }: IUpdateData) => {

  return api
    .put(url + '/' + arg.id, arg)
    .then((res) => res.data)
    .catch((err: AxiosError<any>) => notification.error(getErrorMsg(err)))
}

const deleteFetcher = (url: string, { arg }: IUpdateData) => {
  return api
    .delete(url + '/' + arg.id)
    .then((res) => res.data)
    .catch((err: AxiosError<any>) => notification.error(getErrorMsg(err)))
}

const createFetcher = (url: string, { arg }: IUpdateData) => {
  return api
    .post(url, arg)
    .then((res) => res.data)
    .catch((err: AxiosError<any>) => notification.error(getErrorMsg(err)))
}

import { Fragment, useMemo } from 'react'
import { Button, Flex, Table } from 'antd'

import { toCurrency } from 'utils/numbers'
import { ColumnsType } from 'antd/es/table'

import { AccountFormattedData } from '../Charts'

type DataTableProps = {
  data: any[]
  csvName: string
}

function DataTable(props: DataTableProps) {
  const { data, csvName } = props

  const columns = useMemo(() => generateColumns(data), [data])

  const exportToCSV = () => {
    const csvHeader = columns.map((column) => column.title).join(',') + '\n'

    const csvRows = data
      .map((row) =>
        columns
          //   TODO fix type
          .map((column: any) => {
            const value = row[column.dataIndex]?.toString().replace(',', '')
            return value && value !== '0' ? value : `0.00`
          })
          .join(','),
      )
      .join('\n')

    const csvContent = csvHeader + csvRows

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = csvName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Fragment>
      {data.length ? (
        <Flex style={{ marginTop: '20px', justifyContent: 'flex-end' }}>
          <Button onClick={exportToCSV} type="primary">
            Export to CSV
          </Button>
        </Flex>
      ) : null}
      <Table columns={columns} dataSource={data} rowKey={'name'} />
    </Fragment>
  )
}

const generateColumns = (data: AccountFormattedData[]) => {
  if (!data.length) {
    return []
  }

  let columns: ColumnsType<any> = [{ dataIndex: 'name', title: 'Date' }]

  // console.log(data, columns)

  const { _name, ...extraColumns } = data[0]
  Object.keys(extraColumns).map((i) => columns.push({ dataIndex: i, title: i, render: (v) => toCurrency(v) }))
  return columns.filter((column: any) => column.dataIndex !== 'meta')
}

export default DataTable

import { Link } from 'react-router-dom'
import { Alert, Avatar, Button, Flex, List, Typography } from 'antd'

const { Text } = Typography

export function Advisory() {
  const experts = [
    {
      id: 1,
      name: 'Michael Nieto',
      avatar: 'https://app.equilityhq.com/images/e107df5a-97b3-49e7-99c2-355df32a2fbb.jpeg',
      contact_url: 'https://calendly.com/michaelnieto',
    },
  ]

  const Btn = () => (
    <Link to={experts[0].contact_url} target="__blank">
      <Button type="primary">Book Meeting</Button>
    </Link>
  )

  return (
    <Flex vertical gap={10}>
      <Alert
        message="Welcome to Equility"
        description="As part of your Equility subscription, you can schedule one meeting a month with our founder to discuss you
        financial review results and the best ways to tackle these issues."
      />
      <List
        itemLayout="vertical"
        size="large"
        dataSource={experts}
        renderItem={(item) => (
          <List.Item key={item.id} actions={[<Btn key={`btn-${item.id}`} />]}>
            <List.Item.Meta
              avatar={<Avatar src={item.avatar} size={64} />}
              title={<a href={item.contact_url}>{item.name}</a>}
              description={<i>Founder of Equility</i>}
            />
            <Text>
              I am driven by the efficiency that technology provides. <br />
              By embracing a tech-centric approach to accounting, I hope to build Equility into the cornerstone of every
              financial tech stack.
            </Text>
          </List.Item>
        )}
      />
      <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
        <iframe
          title="EQUILITY: Automating Financial Review Process and Ensuring Accuracy 👨‍💼"
          src="https://www.loom.com/embed/31e0f09abda448f79183fcd8d4dfe4e4?sid=1ff6c5c3-be54-4e04-b63b-b85fc0be829a"
          allowFullScreen
          style={{
            borderRadius: 6,
            border: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </Flex>
  )
}

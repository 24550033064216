import { ConnectionType, IntegrationStatus } from './company.types'

export interface IConnectionItem {
  image: string
  title: string
  disabled?: boolean
  connected?: boolean
  status?: string
}

export enum ConnectionAction {
  Connect,
  Setup,
}


export interface IConnectionData {
  connectionInfo: any;
  keyId: number;
  id: ConnectionType;
  name: string;
  status: IntegrationStatus;
  type: {
    id: ConnectionType;
    name: string;
  };
}


export interface Integrations {
  name: string;
  integrationKeyId: number;
}

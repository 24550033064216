import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'
import googleAnalyticsV3 from '@analytics/google-analytics-v3'
import { config } from './config'

// Initialize analytics with Segment plugin
export const analytics = Analytics({
  app: 'equility',
  plugins: [
    segmentPlugin({
      writeKey: config.segment.writeKey
    }),
    googleAnalyticsV3({
      trackingId: config.ga.id
    })
  ]
})

// Event tracking functions organized by category
export const trackEvents = {
  user: {
    signIn: () => {
      analytics.track('signIn', {
        category: 'user'
      })
    },
    signInGoogle: () => {
      analytics.track('signInGoogle', {
        category: 'user'
      })
    },
    signUp: () => {
      analytics.track('signUp', {
        category: 'user'
      })
    },
    forgotPassword:() => {
      analytics.track('forgotPassword', {
        category: 'user'
      })
    },
    signUpGoogle: () => {
      analytics.track('signUpGoogle', {
        category: 'user'
      })
    }
  },
  compliance: {
    start: () => {
      analytics.track('compliance_start', {
        category: 'compliance'
      })
    },
    error: () => {
      analytics.track('compliance_error', {
        category: 'compliance'
      })
    }
  },
  reconciliation: {
    start: () => {
      analytics.track('reconciliation_start', {
        category: 'reconciliation'
      })
    },
    limit: () => {
      analytics.track('reconciliation_limit_5000', {
        category: 'reconciliation'
      })
    },
    error: () => {
      analytics.track('reconciliation_error', {
        category: 'reconciliation'
      })
    }
  },
  plaid: {
    connect: () => {
      analytics.track('plaid_connect', {
        category: 'plaid'
      })
    },
    error: () => {
      analytics.track('plaid_error', {
        category: 'plaid'
      })
    }
  }
}

// Usage example:
// trackEvents.user.signIn()
// trackEvents.compliance.start()
// trackEvents.reconciliation.limit()
// trackEvents.plaid.connect()

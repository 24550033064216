import { DittofeedSdk } from '@dittofeed/sdk-web';

export const initializeDittofeed = () => {
  // if (process.env.REACT_APP_ENV === 'production') {
    DittofeedSdk.init({
      writeKey: process.env.REACT_DITTO_WRITE_KEY || '',
      host: process.env.REACT_DITTO_HOST || 'https://dittofeed.equilityhq.com',
    })
  // }
}

import useSWR from 'swr'

import { api } from 'utils/axios'
import { IParamsGetProjects, IProject, PaginatedResponse } from '../types/project.types'

export function useProjectList({
  sorting,
  selectedCompanyIds,
  selectedMemberIds,
  selectedStatusIds,
  pagination,
  extraParams,
}: any) {
  const params: any = {
    ...extraParams,
    ...(sorting?.field &&
      sorting.order && {
        orderBy: sorting.field,
        orderDir: sorting.order === 'ascend' ? 'ASC' : 'DESC',
      }),
    ...(selectedCompanyIds?.length && {
      companies: selectedCompanyIds.join(','),
    }),
    ...(selectedMemberIds?.length && {
      user: selectedMemberIds.join(','),
    }),
    ...(selectedStatusIds?.length && {
      status: selectedStatusIds.join(','),
    }),
    limit: pagination.pageSize,
    offset: (pagination.current - 1) * pagination.pageSize,
  }

  const key = ['/project', params]

  const { data, error, mutate, isValidating } = useSWR<PaginatedResponse<IProject>>(key, () => fetch(params))

  const refetch = () => mutate(data, ...key)

  return {
    projectList: data?.data,
    totalCount: data?.count,
    isLoading: !error && !data,
    isError: error,
    mutate,
    refetch,
    isValidating,
  }
}

const fetch = async (params: IParamsGetProjects) => {
  const res = await api.get('/projects', { params })
  return res.data
}

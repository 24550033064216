import { useMatch, useNavigate } from 'react-router-dom'
import { Tabs, TabsProps } from 'antd'
import { Page } from 'components/Page'
import { ProjectsTab } from './Projects'
import { TasksPage } from './Tasks'
import { TemplatesPage } from './Templates'
import { analytics } from '../../utils/analytics'

export function WorkflowPage() {
  const navigate = useNavigate()

  const match = useMatch('/workflow/:tab')
  const selected = match?.params['tab'] || 'projects'

  const onChange = (key: string) => navigate(`/workflow/${key}`)

  analytics.page()

  return (
    <Page title="Workflow">
      <Tabs activeKey={selected} items={items} onChange={onChange} />
    </Page>
  )
}

const items: TabsProps['items'] = [
  {
    label: 'Projects',
    key: 'projects',
    children: <ProjectsTab />,
  },
  {
    label: 'Tasks',
    key: 'tasks',
    children: <TasksPage />,
  },
  {
    label: 'Templates',
    key: 'templates',
    children: <TemplatesPage />,
  },
]

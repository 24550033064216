import { useEffect, useState } from 'react'
import { Input } from 'antd'

interface Props {
  value?: number
  onChange?: (value?: number) => void
  style?: React.CSSProperties
}

export function InputCurrency(props: Props) {
  const { value, onChange: onValueChange, style } = props
  const [displayValue, setDisplayValue] = useState<string>('')
  const [isInitialized, setIsInitialized] = useState(false)

  const _onChange = onValueChange || (() => {})

  // Only set initial value from props
  useEffect(() => {
    if (!isInitialized && value !== undefined && value !== null) {
      setDisplayValue(value.toString())
      setIsInitialized(true)
    }
  }, [value, isInitialized])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value

    // Allow empty input
    if (!input) {
      setDisplayValue('')
      _onChange(undefined)
      return
    }

    // Remove any non-numeric characters except decimal and minus
    const cleaned = input.replace(/[^\d.-]/g, '')

    // Allow single decimal point or minus sign
    if (cleaned === '.' || cleaned === '-') {
      setDisplayValue(cleaned)
      return
    }

    // Handle multiple decimal points
    const parts = cleaned.split('.')
    if (parts.length > 2) return

    // Handle multiple negative signs
    if (cleaned.split('-').length > 2) return

    // Limit decimal places to 2
    if (parts[1]?.length > 2) return

    setDisplayValue(cleaned)

    // Only call onChange for valid numbers
    const numValue = parseFloat(cleaned)
    if (!isNaN(numValue)) {
      _onChange(numValue)
    }
  }

  const handleBlur = () => {
    if (!displayValue || displayValue === '-' || displayValue === '.') {
      setDisplayValue('')
      _onChange(undefined)
      return
    }

    const numValue = parseFloat(displayValue)
    if (!isNaN(numValue)) {
      _onChange(numValue)
    }
  }

  return (
    <Input
      style={style}
      placeholder="0.00"
      prefix="$"
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
      allowClear
    />
  )
}
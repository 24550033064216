import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Flex,
  notification,
  Typography,
  Table,
  Modal,
  TableColumnsType,
} from 'antd'
import { Form, List, Switch, Row } from 'antd'
import { SelectEntity } from 'components/SelectEntity'
import { SelectMethod } from 'components/SelectMethod'
import { AlertPlugin } from 'components/AlertPlugin'

import { useRole } from 'hooks/useRole'
import { useCompany } from 'hooks/useCompany'
import { formatDate, parseDateRage } from 'utils/dates'
import { isPluginInstalled, runReviewSync } from 'utils/plugin'
import { ConnectionType, IRule } from 'types/company.types'
import { RoleType } from 'types/user.types'
import { useFlags } from 'flagsmith/react'

import type { IDefaultRule } from 'types/default-rules.types'
import { useDefaultRules } from '../../../hooks/useDefaultRule'
import { useRules } from '../../../hooks/useRules'

const { RangePicker } = DatePicker
const { Text } = Typography
const dateRange = parseDateRage()

interface SourceItem {
  id: string;
  title: string;
  description: string;
  active: boolean;
  disabled: boolean;
}

export function ReviewRun() {
  const navigate = useNavigate()
  const { rules, remove, isLoading } = useRules()
  const [sourceStates, setSourceStates] = useState<Record<string, boolean>>({})

  const flags = useFlags(['disable_chrome_plugin', 'hide_shopify'])

  const [searchParams] = useSearchParams()
  const [isInitialized, setIsInitialized] = useState(false)

  const { company } = useCompany()
  const { isAllowed } = useRole(RoleType.Member)

  const [form] = Form.useForm()

  const entity = company?.entity
  const method = company?.settings?.accountingMethod || 'Cash'

  const qbo = (company?.connections || []).find((i) => i.type.id === ConnectionType.QBO)
  const isConnected = qbo?.status === 1

  const shopify = company?.connections?.filter((i) => i.type.id === ConnectionType.Shopify)
  const isShopifyConnected = shopify?.some((i) => i.status === 1)

  const sourceData: SourceItem[] = [
    {
      id: 'qbo',
      title: 'QuickBooks Online',
      description: 'Accounting data',
      active: !!isConnected,
      disabled: true,
    },
    {
      id: 'shopify',
      title: 'Shopify',
      description: 'E-commerce data',
      active: !!isShopifyConnected,
      disabled: flags.hide_shopify.enabled,
    },
  ]

  sourceData[0].active = !!isConnected
  sourceData[1].active = !!isShopifyConnected

  const isPlugin = isPluginInstalled()

  // Extract search params processing
  const search: SearchParams = useMemo(() =>
      Object.fromEntries(searchParams) as any
    , [searchParams])


  useEffect(() => {
    if (!company || !search.daterange || !search.sync || isInitialized) return

    const daterange = search.daterange.split('--')
    const state = {
      from: daterange[0],
      to: daterange[1],
      method: company?.settings?.accountingMethod || 'Cash',
      entity: company?.entity,
    }

    setIsInitialized(true)
    localStorage.removeItem('qbosync')
    navigate(`/company/${company.id}/review/result`, { state })
  }, [company, search, isInitialized])


  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [selectedRecord, setSelectedRecord] = useState<any | null>(null)

  const handleRowClick = useCallback((record: IDefaultRule) => {
    setSelectedRecord(record)
    setIsModalVisible(true)
  }, [])


  const closeModal = () => {
    setIsModalVisible(false)
    setSelectedRecord(null)
  }

  const runReview = (state: any) => {
    const path = `/company/${company?.id}/review/result`
    navigate(path, { state })
  }

  const onSubmit = (values: any) => {
    if (!company) return
    if (!isConnected && !flags?.disable_chrome_plugin?.enabled) return notification.error({
      message: 'Error',
      description: 'QuickBooks Online is not connected',
    })

    const state = {
      from: formatDate(values.daterange[0], 'YYYY-MM-DD'),
      to: formatDate(values.daterange[1], 'YYYY-MM-DD'),
      method: values.method,
      entity: values.entity || undefined,
    }

    if (!isPlugin) {
      if (!flags?.disable_chrome_plugin?.enabled) {
        notification.warning({ message: 'Warning', description: 'Equility Plugin is not installed' })
      }
      runReview(state)
    } else {
      const connectionInfo = qbo?.connectionInfo || '{}'
      runReviewSync({
        from: state.from,
        to: state.to,
        companyId: company.id,
        realmId: connectionInfo.realm_id,
        method: state.method,
      })
    }
  }

  // Use the new hook to fetch default rules
  const { data: defaultRules = [], isLoading: isLoadingDefaultRules } = useDefaultRules(entity)

  const onDelete = useCallback((id: number) => {
    remove(id)
  }, [remove])

  const renderActions = useCallback((_: any, rule: IRule) => (
    <Flex gap={10}>
      <Link to={`rules/${rule.id}`}>
        <Button size="small" type="text">
          Edit
        </Button>
      </Link>
      <Button size="small" type="text" danger onClick={() => onDelete(rule.id)}>
        Delete
      </Button>
    </Flex>
  ), [onDelete])

  const columns: TableColumnsType<IRule> = useMemo(() => [
    {
      title: 'Rule',
      dataIndex: 'name',
      width: 300,
      ellipsis: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      ellipsis: true,
    },
    {
      title: 'Source Account',
      dataIndex: 'account',
      width: 300,
      ellipsis: true,
      render: (account) => account?.label,
    },
    {
      title: '',
      dataIndex: 'id',
      width: 150,
      render: renderActions,
    },
  ], [renderActions])

  const handleConnectionToggle = useCallback((sourceId: string, checked: boolean) => {
    setSourceStates(prev => ({
      ...prev,
      [sourceId]: checked,
    }))
  }, [])


  const onRow = useCallback((record: IDefaultRule) => ({
    onClick: () => handleRowClick(record),
  }), [handleRowClick])

  if (!company?.id) return null
  if (!isAllowed) return null

  return (
    <Flex vertical>
      {!isConnected && !flags?.disable_chrome_plugin?.enabled && (
        <Alert
          message="Quickbooks Integration"
          description="Connect your Accounting platform to use the features"
          type="warning"
          showIcon
        />
      )}
      <AlertPlugin />
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={24}>
          <Col span={16}>
            <Divider orientation="left">Review Parameters</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Date Range" name="daterange" initialValue={dateRange}>
                  <RangePicker style={inputStyle} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Accounting Method" name="method" initialValue={method}>
                  <SelectMethod />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Divider orientation="left">Business info</Divider>
            <Form.Item label="Business entity type" name="entity" initialValue={entity}
                       tooltip="Please go within 'Settings' to change your company's entity type">
              <SelectEntity disabled />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Source data</Divider>
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={sourceData}
          renderItem={(item) => (
            <List.Item>
              <Flex vertical gap={10}>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
                <Flex>
                  <Switch
                    checked={sourceStates[item.id] ?? item.active}
                    disabled={item.disabled}
                    onChange={(checked) => handleConnectionToggle(item.id, checked)}
                  />
                </Flex>
              </Flex>
            </List.Item>
          )}
        />
        <Link to="../settings/integrations" relative="path">
          <Button>Setup source data</Button>
        </Link>

        <Flex vertical gap={10}>
          <Flex vertical>
            <Divider orientation="left">Custom Rules</Divider>
            <Table
              loading={isLoading}
              dataSource={rules || []}
              columns={columns}
              rowKey="id"
              pagination={false}
            />
          </Flex>
          <Flex justify="flex-start" style={{ marginTop: 20 }}>
            <Link to="./rules/add">
              <Button type="primary" style={{ width: 200 }}>
                Add Rule
              </Button>
            </Link>
          </Flex>
          <Flex vertical>
            <Divider orientation="left">Default Review Rules</Divider>
            <Flex vertical gap={10}>
              <Text>
                The default rules are applied to all companies by default based on their business entity type.
                Custom rules can be added to make them more specific to your company.
              </Text>
              <Table
                // size="small"
                loading={isLoadingDefaultRules}
                dataSource={defaultRules}
                columns={columns2}
                rowKey="id"
                pagination={false}
                onRow={onRow}
              />
            </Flex>
          </Flex>
        </Flex>

        <Divider />
        <Flex justify="space-between">
          <Button type="primary" style={runStyle} htmlType="submit">
            Run
          </Button>
        </Flex>
      </Form>

      <Modal
        title={selectedRecord?.name}
        open={isModalVisible}
        footer={null}
        onCancel={closeModal}
        width={600}
      >
        {selectedRecord && (
          <Flex vertical gap={16}>
            <Typography.Title level={5}>Description</Typography.Title>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedRecord.description,
              }}
              className="rule-description"
            />
            <style>{`
              .rule-description {
                font-size: 14px;
                line-height: 1.5;
                color: rgba(0, 0, 0, 0.85);
              }
              .rule-description br {
                margin-bottom: 8px;
              }
              .rule-description span {
                display: block;
                margin-bottom: 16px;
              }
              .rule-description ul, 
              .rule-description ol {
                margin: 8px 0;
                padding-left: 24px;
              }
              .rule-description li {
                margin-bottom: 8px;
              }
              .rule-description p {
                margin-bottom: 16px;
              }
            `}</style>
          </Flex>
        )}
      </Modal>
    </Flex>
  )
}

const runStyle: React.CSSProperties = {
  width: 200,
}

const inputStyle: React.CSSProperties = {
  width: '100%',
}

interface SearchParams {
  daterange: string
  sync: string
}


const columns2: TableColumnsType<IDefaultRule> = [
  {
    title: 'Rule',
    dataIndex: 'name',
    width: 300,
    ellipsis: true,
  },
  // {
  //   title: 'Description',
  //   dataIndex: 'description',
  //   render: (description) => (
  //     <span dangerouslySetInnerHTML={{ __html: description }} />
  //   ),
  // },
]
import React, { useState, useMemo } from 'react';
import { Flex, Select, Button } from 'antd';
import { accountName } from 'utils/account';
import type { IAccount } from 'types/account.types';

interface Props {
  accounts: IAccount[];
  value?: any;
  defaultValue?: any;
  width?: number | string;
  placeholder?: string;
  multiple?: boolean;
  oneline?: boolean;
  allowClear?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onChange: (value: any) => void;
}

interface BaseOptionType {
  label: React.ReactNode;
  value: string;
}

interface SelectOptionType extends BaseOptionType {
  name: string;
  type: string;
}

interface SelectGroupOptionType extends BaseOptionType {
  type: 'group';
  options: SelectOptionType[];
}

type Option = SelectOptionType | SelectGroupOptionType;

export function SelectAccount(props: Props) {
  const {
    accounts = [],
    value,
    defaultValue,
    placeholder,
    width = 460,
    multiple,
    oneline,
    allowClear,
    loading,
    disabled,
    onChange
  } = props;

  const [sortBy, setSortBy] = useState<'Name' | 'Account Type'>('Name');
  const [searchText, setSearchText] = useState<string>('');
  const mode = multiple ? 'multiple' : undefined;

  const isGroupOption = (option: Option): option is SelectGroupOptionType => {
    return 'type' in option && option.type === 'group';
  };

  const isSelectOption = (option: Option): option is SelectOptionType => {
    return 'name' in option && 'type' in option && option.type !== 'group';
  };

  const options = useMemo(() => {
    if (sortBy === 'Account Type') {
      const grouped = accounts.reduce((acc, account) => {
        const type = account.type || 'Other';
        if (!acc[type]) acc[type] = [];
        acc[type].push(account);
        return acc;
      }, {} as Record<string, IAccount[]>);

      const groupedOptions: SelectGroupOptionType[] = Object.entries(grouped)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([type, groupAccounts]) => ({
          label: type,
          type: 'group',
          value: type,
          options: groupAccounts
            .sort((a, b) => accountName(a.name, a.number).localeCompare(accountName(b.name, b.number)))
            .map((account): SelectOptionType => ({
              name: accountName(account.name, account.number),
              type: account.type,
              value: account.platformId,
              label: (
                <Flex justify="space-between" align="center" gap={10}>
                  <span>{accountName(account.name, account.number)}</span>
                  <small><i style={labelStyle}>{account.type}</i></small>
                </Flex>
              ),
            }))
        }));

      return groupedOptions;
    }

    const flatOptions: SelectOptionType[] = accounts
      .sort((a, b) => accountName(a.name, a.number).localeCompare(accountName(b.name, b.number)))
      .map((account): SelectOptionType => ({
        name: accountName(account.name, account.number),
        type: account.type,
        value: account.platformId,
        label: (
          <Flex justify="space-between" align="center" gap={10}>
            <span>{accountName(account.name, account.number)}</span>
            <small><i style={labelStyle}>{account.type}</i></small>
          </Flex>
        ),
      }));

    return flatOptions;
  }, [accounts, sortBy]);

  const filterOption = (input: string, option: Option | undefined): boolean => {
    if (!option || !input) return true;

    const searchText = input.toLowerCase();

    if (isGroupOption(option)) {
      return option.options.some(opt =>
        opt.name.toLowerCase().includes(searchText) ||
        opt.type.toLowerCase().includes(searchText)
      );
    }

    if (isSelectOption(option)) {
      if (sortBy === 'Name') {
        return option.name.toLowerCase().includes(searchText);
      } else {
        return option.type.toLowerCase().includes(searchText);
      }
    }

    return false;
  };

  const handleSearch = (text: string) => {
    setSearchText(text);
  };

  const suffixIcon = searchText ? (
    <Flex
      gap={4}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      style={{
        position: 'absolute',
        right: allowClear ? 24 : 8,
        top: '50%',
        transform: 'translateY(-50%)',
        pointerEvents: 'auto',
      }}
    >
      <Button
        size="small"
        type={sortBy === 'Name' ? 'primary' : 'default'}
        onClick={() => setSortBy('Name')}
        style={{ minWidth: 47 }}
      >
        Name
      </Button>
      <Button
        size="small"
        type={sortBy === 'Account Type' ? 'primary' : 'default'}
        onClick={() => setSortBy('Account Type')}
        style={{ minWidth: 80 }}
      >
        Account Type
      </Button>
    </Flex>
  ) : null;

  return (
    <Flex>
      <Select<string, Option>
        placeholder={placeholder ?? 'Select Account'}
        style={{
          width,
          ...searchText ? {
            '& .antSelectSelector': {
              paddingRight: '120px !important'
            }
          } : {}
        }}
        value={value || defaultValue}
        defaultValue={defaultValue}
        options={options}
        loading={loading || !accounts?.length}
        onChange={onChange}
        disabled={!accounts?.length || disabled}
        showSearch
        mode={mode}
        maxTagCount={oneline ? 1 : undefined}
        allowClear={allowClear}
        filterOption={filterOption}
        suffixIcon={suffixIcon}
        onSearch={handleSearch}
        notFoundContent={null}
      />
    </Flex>
  );
}

const labelStyle: React.CSSProperties = {
  paddingRight: 10,
  opacity: 0.5,
};
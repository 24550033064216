import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import { Page } from 'components/Page';
import VideoTooltip  from 'components/VideoTooltip';
import { useRole } from 'hooks/useRole';
import { RoleType } from 'types/user.types';

export function ReconcilePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAllowed } = useRole(RoleType.Member);

  const path = location.pathname.split('/reconcile/')?.[1] || '';
  const activeKey = path.split('/')[0];

  const tabs = items.filter((v) => v.key !== 'result' || activeKey === 'result');

  if (!isAllowed) tabs.splice(0, 1);
  if (!tabs.find((v) => v.key === activeKey)) navigate(tabs[0].key);

  const tabBarExtraContent = {
    right: (
      <VideoTooltip
        videoUrl="https://storage.googleapis.com/equility-website-assets/documentation-videos/Account-reconciliation.mp4"
        videoWidth={720}
      />
    )
  };

  return (
    <Page title="Account reconciliation">
      <Tabs
        items={tabs}
        onChange={navigate}
        activeKey={activeKey}
        tabBarExtraContent={tabBarExtraContent}
      />
      <Outlet />
    </Page>
  );
}

const items = [
  {
    label: 'Run',
    key: '',
  },
  {
    label: 'History',
    key: 'history',
  },
  {
    label: 'Results',
    key: 'result',
  },
];

export default ReconcilePage;
import { useMemo } from 'react'
import { useCompany } from './useCompany'
import { ConnectionType } from 'types/company.types'
import { IConnectionData } from '../types/connection.types'

interface UseConnectionsReturn {
  connections: IConnectionData[];
  isLoading: boolean;
  hasActiveConnections: boolean;
  hasSyncingConnections: boolean;
  activeConnectionsCount: number;
}

export const useConnections = (type: ConnectionType): UseConnectionsReturn => {
  const { company, isLoading } = useCompany()

  return useMemo(() => {
    const connections = (company?.connections || [])
      .filter(conn => conn.type.id === type)
      .map(conn => ({
        ...conn,
        connectionInfo: conn.connectionInfo || {},
      })) as IConnectionData[]
    
    return {
      connections,
      isLoading,
      hasActiveConnections: connections.some(conn => conn.status === 1),
      hasSyncingConnections: connections.some(conn => conn.status === 2),
      activeConnectionsCount: connections.filter(conn => conn.status === 1).length,
    }
  }, [company?.connections, type, isLoading])
}

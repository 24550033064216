import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { Divider, Flex, Table, TablePaginationConfig, Button, Popconfirm } from 'antd'
import { useProject } from 'hooks/useProject'
import { useTasks } from 'hooks/useTasks'
import { useTask } from 'hooks/useTask'
import { formatDate } from 'utils/dates'
import { ProjectStatus } from 'types/project.types'
import { TaskFilters } from './components/TaskFilters'
import { TasksMenu } from './components/TasksMenu'
import { AddTaskModal } from 'components/AddTaskModal'
import { ITask } from 'types/task.types'

const initialPaginationState = { current: 1, pageSize: 10 }

export function TasksPage() {
  const params = useParams()
  const isProjectPage = !!params.id

  const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false)
  const [activeTask, setActiveTask] = useState<ITask | undefined>(undefined)
  // filters
  const [pagination, setPagination] = useState<any>(initialPaginationState)
  const [sorting, setSorting] = useState({ field: null, order: null })
  const [selectedCompanyIds, setSelectedCompaniesId] = useState<number[]>([])
  const [selectedMemberIds, setSelectedMembersIds] = useState<number[]>([])
  const [selectedStatusIds, setSelectedStatusesId] = useState<number[]>([])
  const [titleFilter, setTitleFilter] = useState<string>('')
  const [debouncedTitleFilter] = useDebounce(titleFilter, 500)

  const { get: getProject, project } = useProject()
  const { remove: removeTask, removeLoading } = useTask()
  const {
    tasksList,
    isLoading: tasksLoading,
    isValidating,
    totalCount,
    refetch,
  } = useTasks({
    title: debouncedTitleFilter,
    selectedCompanyIds,
    selectedMemberIds,
    selectedStatusIds,
    sorting,
    pagination,
    project: params.id,
  })

  useEffect(() => {
    if (params.id) getProject(params)
  }, [])

  const handleDelete = (record: ITask) => {
    removeTask({ id: record.id }, refetch)
  }

  const pageTitle = `${project?.name ? project?.name : ''} ${project?.company ? '-' : ''}  ${
    project?.company ? project?.company?.alias || project?.company?.name : ''
  }`.trim()

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    setPagination(pagination)
    setSorting({
      field: sorter.field || sorter.columnKey,
      order: sorter.order,
    })
  }

  const columns = [
    { key: 'sort' },
    ...(isProjectPage
      ? []
      : [
        {
          title: 'Project Name',
          dataIndex: ['project', 'name'],
          sorter: true,
        },
      ]),
    {
      title: 'Task Name',
      dataIndex: 'title',
      field: 'title',
      sorter: true,
    },
    {
      title: 'Assignee',
      dataIndex: 'user',
      field: 'user',
      sorter: true,
      render: (user: any) => {
        return user ? `${user?.firstName} ${user?.lastName}` : '-'
      },
    },
    {
      title: 'Due Date',
      sorter: true,
      field: 'dueDate',
      dataIndex: 'dueDate',
      render: (dueDate: string) => {
        return dueDate ? formatDate(dueDate) : '-'
      },
    },
    {
      title: 'Status',
      sorter: true,
      dataIndex: 'status',
      field: 'status',
      render: (status: number) => {
        return ProjectStatus[status]
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: ITask) => (
        <Popconfirm
          title="Delete Task"
          description="Are you sure you want to delete this task?"
          onConfirm={(e) => {
            e?.stopPropagation();
            handleDelete(record);
          }}
          onCancel={(e) => e?.stopPropagation()}
          okText="Yes"
          cancelText="No"
        >
          <Button
            danger
            loading={removeLoading}
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ]

  useEffect(() => {
    if (tasksList) setPagination((prev: any) => ({ ...prev, total: totalCount }))
  }, [tasksList])

  return (
    <Flex vertical gap={20}>
      {isProjectPage ? (
        <Fragment>
          <TasksMenu project={project} refetchProject={() => getProject(params)} />

          {pageTitle ? (
            <Divider orientation="left">{pageTitle}</Divider>
          ) : (
            <div style={{ minHeight: '57px' }} />
          )}
        </Fragment>
      ) : null}

      <TaskFilters
        isProjectPage={isProjectPage}
        onAddTask={() => setIsAddEditModalVisible(true)}
        company={{ value: selectedCompanyIds, onChange: setSelectedCompaniesId }}
        member={{ value: selectedMemberIds, onChange: setSelectedMembersIds }}
        status={{ value: selectedStatusIds, onChange: setSelectedStatusesId }}
        title={{ value: titleFilter, onChange: setTitleFilter }}
      />

      <Table
        loading={tasksLoading || isValidating}
        dataSource={tasksList}
        columns={columns}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey="id"
        onRow={(record) => {
          return {
            onClick: () => {
              setIsAddEditModalVisible(true)
              setActiveTask(record)
            },
          }
        }}
      />
      <AddTaskModal
        open={isAddEditModalVisible}
        onClose={() => {
          setIsAddEditModalVisible(false)
          setActiveTask(undefined)
        }}
        onSuccess={refetch}
        initialValues={activeTask}
        projectId={params?.id}
      />
    </Flex>
  )
}
import { useEffect } from 'react'
import { create } from 'zustand'
import { Alert, Flex, Steps } from 'antd'

import { FullLoader } from 'components/FullLoader'
import { AccountingStep } from './Accounting'
import { IntegrationStep } from './Integrations'
import { BanksStep } from './Banks'
import { DownloadPluginStep } from './DownloadPlugin'
import { DoneStep } from './Done'

import { useCompanies } from 'hooks/useCompanies'
import { useTeamSubscription } from 'hooks/useStripe'
import type { ICompany } from 'types/company.types'
import { useUser } from 'hooks/useUser'

import { useFlags } from 'flagsmith/react'
import { isPluginNeedInstall } from 'utils/plugin'

interface Props {
  id: number
  onClose: () => void
}

export function AddCompany(props: Props) {
  const { onClose } = props

  const step = useNewCompany((state) => state.step)
  const company = useNewCompany((state) => state.company)
  const setStep = useNewCompany((state) => state.setStep)
  const setCompany = useNewCompany((s) => s.setCompany)

  const { mutate } = useCompanies()
  const { user } = useUser()
  const teamSubscription = useTeamSubscription()
  // Add proper null checks and fallbacks
  const subscriptionStatus = teamSubscription.data?.subscription?.status
  const isTrialing = subscriptionStatus === 'trialing'
  const hasPaymentMethod = !!teamSubscription.data?.paymentMethods?.length
  const accessLevel = !!teamSubscription.data?.subscription?.accessLevel


  const flags = useFlags(['disable_chrome_plugin'])


  const quantity = user?.member?.team?.companies || 0
  const maxQuantity = teamSubscription.data?.subscription?.quantity || 0
  const hasFreeCompany = quantity < maxQuantity


  useEffect(() => {
    setCompany({})
    setStep(0)
  }, [])

  const onStepChange = (step: number) => {
    if (step > 0 && !company?.id) return // validate company
    setStep(step)
  }

  const onDone = () => {
    mutate() // revalidate companies
    setCompany({})
    setStep(0)
    onClose()
  }
  const showPluginStep = !flags?.disable_chrome_plugin?.enabled && isPluginNeedInstall()
  console.info('isPluginInstalled', !showPluginStep)

  const stepsList = [
    {
      title: 'Accounting',
      description: 'Connect your accounting platform',
      content: <AccountingStep onNext={() => onStepChange(step + 1)} />,
    },
    {
      title: 'E-Commerce',
      content: <IntegrationStep onNext={() => onStepChange(step + 1)} onBack={() => onStepChange(step - 1)} />,
    },
    {
      title: 'Banks & Cards',
      content: <BanksStep onNext={() => onStepChange(step + 1)} onBack={() => onStepChange(step - 1)} />,
    },
    showPluginStep && {
      title: 'Download Plugin',
      content: <DownloadPluginStep onNext={() => onStepChange(step + 1)} onBack={() => onStepChange(step - 1)} />,
    },

    {
      title: 'Done',
      content: <DoneStep onDone={onDone} />,
    },
  ].filter((step): step is Exclude<typeof step, false> => Boolean(step))

  if (teamSubscription.isLoading) return <FullLoader />

  if (!accessLevel)
    return (
      <Alert
        message="You need to upgrade your subscription to add a company"
        description="Please contact your account owner to upgrade your subscription."
        type="info"
        showIcon
      />
    )

  if (!isTrialing && !hasPaymentMethod && !hasFreeCompany)
    return (
      <Alert
        message="You need to add a payment method before adding a company"
        description="Please navigate to the billing portal to add a payment method or contact your account owner."
        type="info"
        showIcon
      />
    )

  return (
    <Flex vertical>
      <Steps current={step} items={stepsList as any} onChange={onStepChange} />
      <Flex vertical>{stepsList[step].content}</Flex>
    </Flex>
  )
}

export interface CompanyState {
  step: number
  company: Partial<ICompany> | null
  setStep: (step: number) => void
  setCompany: (company: Partial<ICompany>) => void
}

export const useNewCompany = create<CompanyState>((set) => ({
  step: 0,
  company: null,
  setStep: (step) => set({ step }),
  setCompany: (company) => set({ company }),
}))

import { useState } from 'react'
import { Button, Card, Divider, Flex, List, Typography, notification } from 'antd'

import { api } from 'utils/axios'
import { useStripe } from 'hooks/useStripe'
import { toCurrency } from 'utils/numbers'
import { useCompanies } from 'hooks/useCompanies'
import { getErrorMsg } from 'utils/geterror'

const { Text, Title } = Typography

export function ShowCase() {
  const { plan, subscription } = useStripe()
  const { data: companies } = useCompanies()

  const [period] = useState<'Monthly' | 'Annually'>('Monthly')
  const [loading, setLoading] = useState(false)

  const accessLevel = subscription?.accessLevel || 0

  const list: Item[] = (plan?.list || []).map((item) => ({ ...item, active: item.accessLevel === accessLevel }))

  const getUnitPrice = (price: number) => {
    return period === 'Monthly' ? price : price - price * 0.1
  }

  const getPrice = (price: number) => {
    const p = getUnitPrice(price)
    return p ? toCurrency(p) : 'Free'
  }

  const setTotalPrice = (price: number) => {
    const p = getUnitPrice(price)
    if (!p) return 'Free'
    return period === 'Monthly' ? toCurrency(p) || 'Free' : toCurrency(p * 12) || 'Free'
  }

  const onClick = (item: Item) => {
    const quantity = Math.max(companies?.length || 0, 1)
    setLoading(true)
    getCheckoutUrl(quantity, item.id)
      .then((r) => (window.location.href = r.data))
      .catch((err) => notification.error(getErrorMsg(err)))
      .finally(() => setLoading(false))
  }

  function getCheckoutUrl(quantity: number, price: string) {
    const returnUrl = window.location.href
    const params = { quantity, price, returnUrl }
    return api.get('/user/subscription/checkout', { params })
  }

  return (
    <Flex vertical>
      <List
        grid={{ gutter: 10, column: 3 }}
        dataSource={list.filter((v) => v.accessLevel)}
        rowKey={(item) => item.id}
        renderItem={(item) => (
          <List.Item>
            <Card title={<CardHeader item={item} period={period} />} size="small">
              <Title level={5} style={titleStyle}>
                Price per company: {getPrice(item.price)}
              </Title>
              <Flex vertical>
                <Text>My Companies: {companies?.length || 0}</Text>
                <Text>
                  Subscription price: {setTotalPrice(item.price)} <small>{!!item.price && period}</small>
                </Text>
                <Divider style={titleStyle} />
                {item.accessLevel === 0 && (
                  <>
                    <Text>&nbsp;</Text>
                    <Text>&nbsp;</Text>
                    <Text>&nbsp;</Text>
                    <Text>&nbsp;</Text>
                  </>
                )}
                {item.accessLevel === 1 && (
                  <>
                    <Text>Account Reconciliation</Text>
                    <Text>Bank integration</Text>
                    <Text>&nbsp;</Text>
                    <Text>&nbsp;</Text>
                  </>
                )}
                {item.accessLevel === 2 && (
                  <>
                    <Text>Account Reconciliation</Text>
                    <Text>Bank integration</Text>
                    <Text>Financial Review</Text>
                    <Text>E-commerce</Text>
                  </>
                )}
              </Flex>
              <Divider style={titleStyle} />
              {!!item.price && (
                <Button type="primary" block onClick={() => onClick(item)} loading={loading}>
                  Get {item.name}
                </Button>
              )}
              {!item.price && (
                <Button type="text" block disabled>
                  Active
                </Button>
              )}
            </Card>
          </List.Item>
        )}
      />
      {/*{false && (*/}
      {/*  <Flex justify="center">*/}
      {/*    <Segmented value={period} options={['Monthly', 'Annually']} onChange={setPeriod} />*/}
      {/*  </Flex>*/}
      {/*)}*/}
    </Flex>
  )
}

function CardHeader({ item, period }: { item: Item; period: string }) {
  return (
    <Flex justify="space-between" align="center">
      <Title level={5} style={titleStyle}>
        <Text>{item.name}</Text>
      </Title>
      {!item.price && <Text></Text>}
      {!!item.price && <Text type="secondary">{period}</Text>}
    </Flex>
  )
}

const titleStyle: React.CSSProperties = {
  marginTop: 10,
  marginBottom: 10,
}

interface Item {
  id: string
  active: boolean
  name: string
  price: number
  accessLevel: number
}

import { Button, Divider, Flex, List, Modal, Tag, Typography, notification } from 'antd'
import { CardIntegration } from 'components/CardIntegration'
import { MatchAccounts } from 'components/MatchAccounts'
import { api } from 'utils/axios'
import { useRole } from 'hooks/useRole'
import { useCompany } from 'hooks/useCompany'
import { useAccounts } from 'hooks/useAccounts'
import { useCompanyStore } from 'store/company.store'
import { ConnectionType } from 'types/company.types'
import { IConnectionItem, ConnectionAction } from 'types/connection.types'
import { RoleType } from 'types/user.types'
import { useState } from 'react'
import { useFlags } from 'flagsmith/react'
import ShopifyConnectModal from '../../../components/ShopifyConnectModal'
import { useConnections } from '../../../hooks/useConnections'

const { Text } = Typography

export function Integrations() {
  const companyId = useCompanyStore((state) => state.id)
  const { company, update, revalidate } = useCompany()
  const { accounts, isLoading } = useAccounts()
  const { isAllowed } = useRole(RoleType.Member)
  const [loading, setLoading] = useState<boolean>(false)
  const [shopifyModalVisible, setShopifyModalVisible] = useState<boolean>(false)
  const [setupModalVisible, setSetupModalVisible] = useState<boolean>(false)
  const flags = useFlags(['hide_shopify'])
  const {
    connections: shopifyConnections,
    hasActiveConnections,
    hasSyncingConnections,
    activeConnectionsCount,
  } = useConnections(ConnectionType.Shopify)

  const items: IConnectionItem[] = [
    {
      image: require('assets/shopify.png'),
      title: 'Shopify',
      disabled: flags.hide_shopify.enabled,
      connected: true,
      status: hasSyncingConnections ? 'syncing' : (hasActiveConnections ? 'connected' : 'not connected'),
    },
    {
      image: require('assets/amazon.png'),
      title: 'Amazon',
      disabled: true,
    },
    {
      image: require('assets/walmart.png'),
      title: 'Walmart',
      disabled: true,
    },
    {
      image: require('assets/etsy.png'),
      title: 'Etsy',
      disabled: true,
    },
    {
      image: require('assets/squarespace.png'),
      title: 'Squarespace',
      disabled: true,
    },
    {
      image: require('assets/ebay.png'),
      title: 'Ebay',
      disabled: true,
    },
  ]

  const connectShopify = async (storeUrl: string) => {
    if (!isAllowed) {
      notification.error({ message: 'Error', description: 'You are not allowed to perform this action' })
      return
    }
    if (!storeUrl) {
      notification.error({ message: 'Error', description: 'Please enter your Shopify store URL' })
      return
    }
    // Check if store is already connected
    const storeExists = shopifyConnections.some(
      conn => conn.connectionInfo?.store?.domain === storeUrl ||
        conn.connectionInfo?.realmId === storeUrl,
    )

    if (storeExists) {
      notification.error({ message: 'Error', description: 'This Shopify store is already connected' })
      return
    }
    setLoading(true)
    try {
      const response = await api.post(`companies/${company?.id || companyId}/connection/link`, {
        provider: 'Shopify',
        realmId: storeUrl,
      })
      const authUrl = response.data.url
      if (authUrl) {
        const authWindow = window.open(authUrl, '_blank', 'width=600,height=600')

        if (authWindow) {
          const pollTimer = setInterval(async () => {
            if (authWindow.closed) {
              clearInterval(pollTimer)
              await revalidate()
              setLoading(false)
              setShopifyModalVisible(false)
              notification.success({ message: 'Shopify connection added successfully' })
            }
          }, 500)
        } else {
          notification.error({
            message: 'Error',
            description: 'Unable to open authentication window. Please check your pop-up blocker settings.',
          })
          setLoading(false)
        }
      }
    } catch (error: any) {
      notification.error({
        message: 'Error',
        description: error.response?.data?.message || 'Failed to connect to Shopify',
      })
      setLoading(false)
    }
  }

  const onDisconnect = async (keyId: any) => {
    if (!isAllowed) {
      notification.error({ message: 'Error', description: 'You are not allowed to perform this action' })
      return
    }
    setLoading(true)
    try {
      await api.delete(`companies/${company?.id}/connection/${keyId}`)
      notification.success({ message: 'Shopify store disconnected successfully' })
      await revalidate()
    } catch (error: any) {
      notification.error({ message: 'Error', description: error.message || 'Failed to disconnect from Shopify' })
    } finally {
      setLoading(false)
    }
  }

  const onClick = (item: IConnectionItem, action: ConnectionAction) => {
    if (action === ConnectionAction.Connect) {
      if (item.title === 'Shopify') {
        setShopifyModalVisible(true)
      }
    } else if (action === ConnectionAction.Setup) {
      setSetupModalVisible(true)
    }
  }

  const onMatchedChange = (value: Matched) => {
    const settings = { ...company?.settings, merchant: { matched: value } }
    update({ ...company, settings })
  }

  const matched = company?.settings?.merchant?.matched || {}

  if (!company) return null

  return (
    <Flex vertical>
      <Divider orientation="left">Integration</Divider>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={items}
        renderItem={(item) => (
          <List.Item key={item.title}>
            <CardIntegration item={item} onClick={onClick} />
          </List.Item>
        )}
      />
      {hasActiveConnections && (
        <Flex vertical gap={20}>
          <Divider orientation="left">Settings</Divider>
          <Text>We need to know how you match your e-commerce data to your general ledger accounts</Text>
          <MatchAccounts
            value={matched}
            onChange={onMatchedChange}
            accounts={accounts}
            loading={isLoading}
            options={shopifyAccounts}
            placeholder="Select Account(s)"
            multiple
            disabled={!isAllowed}
          />
        </Flex>
      )}

      <Modal
        title="Shopify Integrations"
        open={setupModalVisible}
        onCancel={() => setSetupModalVisible(false)}
        footer={null}
        width={600}
      >
        <Flex vertical gap={10} style={{ marginTop: 10, marginBottom: 20 }}>
          <Text strong>Connected stores ({activeConnectionsCount}):</Text>
          {shopifyConnections.map((connection) => (
            <Flex key={connection.keyId} justify="space-between" align="center">
              <Text>
                {connection.connectionInfo?.store?.store_name || 'Store'}
                {connection.connectionInfo?.store?.domain &&
                  ` (${connection.connectionInfo.store.domain})`
                }
              </Text>
              <Flex gap={8} align="center">
                <Tag color={connection.status === 1 ? 'blue' : 'orange'}>
                  {connection.status === 1 ? 'active' : 'syncing'}
                </Tag>
                <Button
                  danger
                  size="small"
                  onClick={() => onDisconnect(connection.keyId)}
                  loading={loading}
                >
                  Disconnect
                </Button>
              </Flex>
            </Flex>
          ))}
          <Divider />
          <Button
            type="primary"
            onClick={() => {
              setSetupModalVisible(false)
              setShopifyModalVisible(true)
            }}
          >
            Connect New Store
          </Button>
        </Flex>
      </Modal>

      <ShopifyConnectModal
        visible={shopifyModalVisible}
        onCancel={() => setShopifyModalVisible(false)}
        onConnect={connectShopify}
        loading={loading}
      />
    </Flex>
  )
}

type Matched = { [key: string]: string[] }

const shopifyAccounts = [
  { id: 'gross', name: 'Gross Sales', isCalc: false },
  { id: 'discount', name: 'Discounts', isCalc: false },
  { id: 'returns', name: 'Returns', isCalc: false },
  { id: 'net', name: 'Net Sales', isCalc: true },
  { id: 'shipping', name: 'Shipping', isCalc: false },
  // {id: 'total', name: 'Total', isCalc: true},
  // {id: 'payments', name: 'Payments', isCalc: true},
]
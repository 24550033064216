import React from 'react'
import { AlertPlugin } from 'components/AlertPlugin'
import { Divider } from 'antd'


interface DownloadPluginStepProps {
  onNext?: () => void,
  onBack?: () => void
}

// eslint-disable-next-line no-empty-pattern
export function DownloadPluginStep({}: DownloadPluginStepProps) {
  return (
    <div>
      <Divider orientation="left">
        Our plug-in is supported on Chrome, Edge, or Brave. Please use one of these browsers for optimal functionality.
      </Divider>
      <AlertPlugin />
    </div>
  )
}

import { Link, useMatch } from 'react-router-dom'
import { Menu, theme, Tooltip } from 'antd'
import type { ItemType } from 'antd/es/menu/interface'
import type { MenuItemType } from 'antd/es/menu/interface'
import { useFlags } from 'flagsmith/react';
import * as Icon from '@ant-design/icons'

import { useUser } from 'hooks/useUser'
import { config } from 'utils/config'
import { analytics } from '../utils/analytics'

export function AppMenu() {
  const { user, logout } = useUser()

  const match = useMatch('/*')
  const fullPath = match?.params['*']
  const path = fullPath?.split('/')?.[0]
  const flags = useFlags(['disable_chrome_plugin'])

  // Filter out the plugin download item if the flag is true (disabled)
  const filteredMenuItems = flags?.disable_chrome_plugin?.enabled
    ? menuItems.filter((item): item is ItemType<MenuItemType> =>
      item !== null && item.key !== 'download'
    )
    : menuItems

  const items = user?.admin ? [...adminItems, ...filteredMenuItems] : filteredMenuItems
  const home = user?.admin ? items[1]?.key : items[0]?.key
  const flatItems = items.flatMap((item: any) => (item?.children ? item?.children : item))
  const selected = path
    ? flatItems.find((item) => path === '' + item?.key || fullPath === '' + item?.key)?.key || ''
    : home

  // @ts-ignore // logout onClick
  items[items.length - 1].onClick = () => logout()

  if (user) {
    analytics.identify(String(user.id), {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    })
  }


  return <Menu mode="inline" items={items} selectedKeys={[selected as string]} style={menuStyle} />
}



const adminItems: ItemType<MenuItemType>[] = [
  {
    label: <Link to="/admin">Admin</Link>,
    key: 'admin',
    icon: <Icon.AlertOutlined />,
  },
]

const menuItems: ItemType<MenuItemType>[] = [
  {
    label: <Link to="/">All Companies</Link>,
    key: 'companies',
    icon: <Icon.ProductOutlined />,
  },
  {
    label: 'Workflow',
    key: 'workflow',
    icon: <Icon.CarryOutOutlined />,
    children: [
      {
        label: <Link to="/workflow/projects">Projects</Link>,
        key: 'workflow/projects',
      },
      {
        label: <Link to="/workflow/tasks">Tasks</Link>,
        key: 'workflow/tasks',
      },
      {
        label: <Link to="/workflow/templates">Templates</Link>,
        key: 'workflow/templates',
      },
    ],
  },
  {
    label: <Link to="/account">Account</Link>,
    key: 'account',
    icon: <Icon.UserOutlined />,
  },
  {
    label: <Link to="/team">Team</Link>,
    key: 'team',
    icon: <Icon.TeamOutlined />,
  },
  {
    label: <Link to="/help">Help</Link>,
    key: 'help',
    icon: <Icon.InfoCircleOutlined />,
  },
  {
    label: (
      <Tooltip title="Our plug-in is supported on Chrome, Edge, or Brave. Please use one of these browsers for optimal functionality.">
        <a href={config.links.downloadPlugin} target="_blank" rel="noreferrer">
          Download Plug-in
        </a>
      </Tooltip>
    ),
    key: 'download',
    icon: <Icon.DownloadOutlined />,
  },
  {
    label: 'Logout',
    key: 'signout',
    icon: <Icon.LogoutOutlined />,
    // onClick: () => logout(),
  },
]

const menuStyle: React.CSSProperties = {
  borderRadius: theme.getDesignToken().borderRadius,
}

import { useEffect, useState } from 'react'
import { Flex, Progress, Table, TablePaginationConfig, Button, Popconfirm } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ProjectFilters } from './components/ProjectFilters'
import { useProjectList } from 'hooks/useProjects'
import { useProject } from 'hooks/useProject'
import { formatDate } from 'utils/dates'
import { ProjectStatus, ProjectType } from 'types/project.types'
import type { IUser } from 'types/user.types'
import type { IProject } from 'types/project.types'

const initialPaginationState: TablePaginationConfig = { current: 1, pageSize: 10 }

export function ProjectsTab() {
  const navigate = useNavigate()
  const { remove, removeLoading } = useProject()

  const [pagination, setPagination] = useState<TablePaginationConfig>(initialPaginationState)
  const [sorting, setSorting] = useState({ field: null, order: null })
  const [selectedCompanyIds, setSelectedCompaniesId] = useState<number[]>([])
  const [selectedMemberIds, setSelectedMembersIds] = useState<number[]>([])
  const [selectedStatusIds, setSelectedStatusesId] = useState<number[]>([])

  const { projectList, totalCount, isLoading, isValidating, refetch } = useProjectList({
    sorting,
    selectedCompanyIds,
    selectedMemberIds,
    selectedStatusIds,
    pagination,
    extraParams: { type: ProjectType.BASE },
  })

  const handleTableChange = (pagination: TablePaginationConfig, _: any, sorter: any) => {
    setPagination(pagination)
    setSorting({
      field: sorter.field || sorter.columnKey,
      order: sorter.order,
    })
  }

  const handleDelete = (record: IProject) => {
    remove({ id: record.id }, refetch)
  }

  useEffect(() => {
    if (projectList) setPagination((prev: any) => ({ ...prev, total: totalCount }))
  }, [projectList])

  const columns = [
    {
      title: 'Project',
      field: 'name',
      key: 'name',
      sorter: true,
      render: (row: IProject) => {
        const { name } = row || {}
        return (
          <Flex style={{ flexDirection: 'column', alignItems: 'start' }}>
            <span>{name}</span>
          </Flex>
        )
      },
    },
    {
      title: 'Completion Meter',
      field: 'completionMeter',
      key: 'completionMeter',
      render: (row: IProject) => {
        const { allTasks, completedTasks } = row || {}
        const percent = allTasks > 0 ? (completedTasks / allTasks) * 100 : 0

        return (
          <Flex style={{ flexDirection: 'column', alignItems: 'start' }}>
            {allTasks === 0 ? (
              <p>No tasks available</p>
            ) : (
              <Progress style={{ width: 300 }} percent={percent} format={() => `${completedTasks}/${allTasks}`} />
            )}
          </Flex>
        )
      },
    },
    {
      title: 'Client',
      field: 'application',
      key: 'application',
      sorter: true,
      render: (row: IProject) => {
        const { alias, name } = row.company || {}
        return <Flex>{alias || name || '-'}</Flex>
      },
    },
    {
      title: 'Start Date',
      field: 'startDate',
      dataIndex: 'startDate',
      sorter: true,
      render: (startDate: string) => {
        return <Flex>{startDate ? formatDate(startDate, 'YYYY-MM-DD') : '-'}</Flex>
      },
    },
    {
      title: 'Due Date',
      field: 'dueDate',
      sorter: true,
      dataIndex: 'dueDate',
      render: (dueDate: string) => {
        return <Flex>{dueDate ? formatDate(dueDate, 'YYYY-MM-DD') : '-'}</Flex>
      },
    },
    {
      title: 'Assignee',
      dataIndex: 'user',
      key: 'user',
      field: 'user',
      sorter: true,
      render: (user: IUser) => {
        return (
          <p>
            {user?.firstName} {user?.lastName}
          </p>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      field: 'status',
      dataIndex: 'status',
      sorter: true,
      render: (status: null) => {
        return <Flex>{status ? ProjectStatus[status] : ''}</Flex>
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: IProject) => (
        <Popconfirm
          title="Delete Project"
          description="Are you sure you want to delete this project?"
          onConfirm={(e) => {
            e?.stopPropagation();
            handleDelete(record);
          }}
          onCancel={(e) => e?.stopPropagation()}
          okText="Yes"
          cancelText="No"
        >
          <Button
            danger
            loading={removeLoading}
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ]

  return (
    <>
      <Flex vertical gap={20}>
        <ProjectFilters
          company={{ value: selectedCompanyIds, onChange: setSelectedCompaniesId }}
          member={{ value: selectedMemberIds, onChange: setSelectedMembersIds }}
          status={{ value: selectedStatusIds, onChange: setSelectedStatusesId }}
          refetch={refetch}
        />

        <Table
          loading={isLoading || isValidating}
          dataSource={projectList}
          columns={columns}
          pagination={pagination}
          onChange={handleTableChange}
          rowKey="id"
          onRow={(record) => ({
            onClick: () => navigate(`/workflow/projects/${record.id}`),
          })}
        />
      </Flex>
    </>
  )
}